import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl, navigate } from 'gatsby-plugin-intl'

import AccountWrapper from './wrapper'
import CookieSettings from '../footer/cookieSettings'

import media, { sizes } from '../../components/css/media'
import success_icon from '../../images/success_icon.svg'
import storageService from '../../services/storageService'
import { updateAccount } from '../../adapters/user'
import Modal from '../../components/common/modal'
import useModal from '../../hooks/useModal'
import toast from '../../components/common/toast'

import ChevronLeftIcon from '../../images/icon_chevron_left_white.svg'
import TimesIcon from '../../images/icon_times_white.svg'

const Tabs = styled.div`
  justify-content: center;
  display: flex;
`

const Ul = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  ${media.tablet`
    width: auto;
  `};
`

const Li = styled.li`
  cursor: pointer;
  box-sizing: border-box;
  padding: 1rem 0;
  width: 50%;
  text-align: center;
  font-size: 0.9em;
  flex: 1;

  &:hover,
  &.active {
    border-bottom: 3px solid #da0000;
    padding-bottom: calc(1rem - 3px);
    color: #da0000;
  }

  ${media.tablet`
    margin: 0 .5rem;
    padding: 1rem;
    font-size: 1em;
    width: auto;
    flex: auto;

    &:hover,
    &.active {
      color: #000;
    }
  `};
`

const Content = styled.div`
  padding: 1rem;
  button {
    background-color: #fff;
    border-color: #000;
    color: #000;
    padding: 0 2rem;
    line-height: 45px;
  }
`

const CheckboxWrapper = styled.div`
  font-size: 0.9em;
  display: flex;
  margin: 2rem 0 1rem 0;
  position: relative;
  cursor: pointer;
  border-radius: 6px;
  padding: 1rem;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked ~ label {
      &:before {
        background-color: #fff;
        border: 3px solid #31c27c;
        color: #31c27c;
        background-image: url('${success_icon}');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
      }
    }
  }

  label {
    padding: 0 0 0 2rem;

    &:before {
      display: block;
      position: absolute;
      user-select: none;
      height: 32px;
      width: 32px;
      border-radius: 6px;
      background-color: #eee;
      border: 3px solid #dbdbdb;
      pointer-events: none;
      content: '';
      left: 0;
      top: 0.5rem;
      box-sizing: border-box;
    }

    &:after {
      content: '';
      left: 0;
      top: 0;
      background: no-repeat 50%/50% 50%;
      display: block;
      width: 1rem;
      height: 1rem;
      position: absolute;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.invalid {
    label {
      &:before {
        border: 3px solid #ff8282;
      }
    }
  }
`

const Form = styled.form`
  input[type='submit'] {
    white-space: break-spaces;
  }
`

const Title = styled.div`
  text-align: center;
  font-weight: 300;
  background-color: #000;
  color: #fff;
  line-height: 50px;
  padding: 0 1rem;

  > img {
    float: left;
    margin: 0.9rem 0 0 0;

    &:last-child {
      float: right;
    }
  }

  ${media.tablet`
    font-size: 1.5em;
    font-weight: 600;
    color: #000;
    line-height: initial;
    padding: 0;
    background-color: #fff;
    text-align: left;

    > img {
      display: none;
    }
  `};
`

const Settings = () => {
  const intl = useIntl()

  const breakpoint = sizes.tablet
  const { closeModal } = useModal()
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : breakpoint
  )

  const onResizeWindow = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResizeWindow)
    }

    return () => {
      window.removeEventListener('resize', onResizeWindow)
    }
  })

  const handleClose = (action) => {
    closeModal()
    action === 'close' ? navigate('/casino') : navigate('/account')
  }

  return (
    <AccountWrapper
      titleOnly
      title={intl.formatMessage({ id: 'navigation.settings' })}
    >
      {width < breakpoint && (
        <Modal
          theme='account'
          title={''}
          isOpen={width < breakpoint}
          onClose={handleClose}
        >
          <SettingsContent closeModal={handleClose} />
        </Modal>
      )}

      {width > breakpoint && <SettingsContent />}
    </AccountWrapper>
  )
}

const SettingsContent = ({ closeModal }) => {
  const intl = useIntl()
  const [tab, setTab] = useState(0)
  const [gdpr, setGdpr] = useState(false)

  const { register, handleSubmit } = useForm()

  useEffect(() => {
    const user = storageService.getUser()
    setGdpr(
      user.accept_gdpr &&
        user.accept_gdpr_marketing &&
        user.accept_news &&
        user.accept_sms
    )
  }, [])

  const onSubmit = async () => {
    let profile = Object.assign({}, storageService.getUser())

    profile.accept_gdpr = gdpr
    profile.accept_gdpr_marketing = gdpr
    profile.accept_news = gdpr
    profile.accept_sms = gdpr

    const response = await updateAccount(profile)
    if (response.ok) {
      storageService.setUser(JSON.stringify(profile))
      toast.success(
        intl.formatMessage({ id: 'account.profile.marketingSettingsSaved' })
      )
    }
  }

  const changeHandler = () => {
    setGdpr(!gdpr)
  }

  return (
    <>
      {closeModal && (
        <Title>
          <img
            src={ChevronLeftIcon}
            alt='Left'
            width={12}
            onClick={() => closeModal('back')}
          />
          <FormattedMessage id='navigation.settings' />
          <img
            src={TimesIcon}
            alt='Close'
            width={12}
            onClick={() => closeModal('close')}
          />
        </Title>
      )}
      <Tabs>
        <Ul>
          <Li className={tab === 0 ? 'active' : ''} onClick={() => setTab(0)}>
            <FormattedMessage id='account.settings.stayUpdated' />
          </Li>
          <Li className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>
            <FormattedMessage id='common.cookie.settings' />
          </Li>
        </Ul>
      </Tabs>
      <Content>
        {tab === 0 && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CheckboxWrapper onClick={changeHandler}>
              <input
                type='checkbox'
                checked={`${gdpr ? 'checked' : ''}`}
                {...register('gdpr')}
              />
              <label htmlFor='gdpr'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: 'register.gdpr' }),
                  }}
                />
              </label>
            </CheckboxWrapper>
            <input
              type='submit'
              className='secondary'
              value={intl.formatMessage({ id: 'common.saveSettings' })}
            />
          </Form>
        )}
        {tab === 1 && <CookieSettings />}
      </Content>
    </>
  )
}

export default Settings
