import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import Button from '../../components/common/button'
import useCookie from '../../hooks/useCookie'

import toast from '../../components/common/toast'

import success_icon from '../../images/success_icon.svg'

const Wrapper = styled.div``

const H1 = styled.h1`
  font-size: 1.6em;
  font-weight: 600;
  margin: 2rem 0 0 0;
`

const P = styled.p`
  font-size: 0.9em;
  line-height: 1.2em;
  margin: 0.5rem 0;

  a {
    color: #000;
  }
`

const CheckboxWrapper = styled.div`
  font-size: 0.9em;
  display: flex;
  margin: 0 0 1rem 0;
  position: relative;
  cursor: pointer;
  background-color: rgb(238, 238, 238);
  border-radius: 6px;
  padding: 1rem;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked ~ label {
      &:before {
        background-color: #fff;
        border: 3px solid #31c27c;
        color: #31c27c;
        background-image: url('${success_icon}');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
      }
    }
  }

  label {
    padding: 0 0 0 3rem;

    &:before {
      display: block;
      position: absolute;
      user-select: none;
      height: 32px;
      width: 32px;
      border-radius: 6px;
      background-color: #eee;
      border: 3px solid #dbdbdb;
      pointer-events: none;
      content: '';
      left: 1rem;
      top: 0.5rem;
      box-sizing: border-box;
    }

    &:after {
      content: '';
      left: 0;
      top: 0;
      background: no-repeat 50%/50% 50%;
      display: block;
      width: 1rem;
      height: 1rem;
      position: absolute;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.invalid {
    label {
      &:before {
        border: 3px solid #ff8282;
      }
    }
  }
`

const ButtonWrapper = styled.div`
  text-align: center;
`

const CookieSettings = (props) => {
  const intl = useIntl()
  const [cookie, updateCookie] = useCookie('caccept', '')
  const [cookieChecked, setCookieChecked] = useState(
    cookie === 'on' ? true : false
  )

  const onInputChange = () => {
    setCookieChecked((c) => !c)
  }

  const onSubmit = () => {
    if (props.onClose) {
      props.onClose()
    }
    updateCookie(cookieChecked ? 'on' : 'off', 365)
    toast.success(intl.formatMessage({ id: 'common.cookie.saveSettings' }))
  }

  return (
    <Wrapper>
      <H1>
        <FormattedMessage id='common.cookie.necessaryCookies' />
      </H1>
      <P
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'common.cookie.necessaryCookiesDisclaimer',
          }),
        }}
      />

      <CheckboxWrapper>
        <input
          name='necessaryCookies'
          type='checkbox'
          checked={`checked`}
          disabled
        />
        <label htmlFor='necessaryCookies' className='disabled'>
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: 'common.cookie.necessaryCookies',
              }),
            }}
          />
        </label>
      </CheckboxWrapper>

      <H1>
        <FormattedMessage id='common.cookie.advertCookies' />
      </H1>
      <P>
        <FormattedMessage id='common.cookie.advertCookiesDisclaimer' />
      </P>

      <CheckboxWrapper onClick={onInputChange}>
        <input
          name='advertCookies'
          type='checkbox'
          checked={`${cookieChecked ? 'checked' : ''}`}
        />
        <label htmlFor='advertCookies'>
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'common.cookie.advertCookies' }),
            }}
          />
        </label>
      </CheckboxWrapper>

      <ButtonWrapper>
        <Button
          text={intl.formatMessage({ id: 'common.cookie.saveSettings' })}
          bgcolor='#31c27c'
          padding='.5rem 2rem'
          onClick={onSubmit}
        />
      </ButtonWrapper>
    </Wrapper>
  )
}

export default CookieSettings
